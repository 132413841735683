import { application } from '@/controllers/application'

// Launch Fast on page opening
document.addEventListener('turbo:load', async () => {
  if (window.location.hash.includes('#open-fast') || window.location.pathname === '/fonctionnement-codeur') {
    const fast = await import('@/apps/fast/main.js')
    await application.register('fast', fast.default)
    window.FastApp.launch(window.location.search)
    $('.hiw-tmp-overlay').fadeOut()
  }
})
